.user-list-form-drawer{
  .ant-drawer-footer{
    padding: 0 !important;
  }
}

.user-list-extra-filter{
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.menu-whole-wrapper{
  position: relative;
  border-bottom: 1px solid #edf2f9;
  .filter-displayer{
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }
  .menu-wrapper{
    margin-left: 150px;
    .ant-menu{
      border: none;
    }
  }
}

.styled-dropzone-files{
  .ant-list{
    border-radius: 0 !important;
  }
}

.styled-dropzone-progress{
  border: 1px solid #edf2f9;
  .ant-progress-bg{
    border-radius: 0 !important;
  }
  .ant-progress-inner{
    border-radius: 0 !important;
  }
}

.ant-table-tbody > tr.single-click-select{
  &:not(.disable-selection){
    cursor: pointer;
  }
  &.disable-selection{
    cursor: not-allowed;
    td{
      background-color: #fafafa;
    }
  }
}

.add-user-listing{
  .record-identity-info{
    position: relative;
    display: flex;
    align-items: center;
    .icon-holder{
      position: absolute;
    }
    .record-identity{
      margin-left: 20px;
    }
  }
}

.custom-filter-wrapper{
  display: inline-block;
  .custom-filter{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: #fafafa;
    border: 1px solid #edf2f9;
    .filter-label{
      padding-right: 10px;
      padding-left: 10px;
    }
    .filter-sort{
      padding-left: 5px;
      padding-right: 3px;
      cursor: pointer;
      line-height: 0;
      .sort-dir{
        &.on{
          color: $primary;
        }
      }
      .up{

      }
      .down{

      }
    }
    .filter-widget{
      background-color: white;
      flex: 1;
    }

    .ant-select-selector {
      border: none;
    }
  }
}
