.category-content-page{
  //height: 80vh;

  min-height: calc(100vh - 130px - 25px * 2 - 2px);

  .first-col {
    padding-right: 10px;
  }
  .second-col {
    padding-left: 5px;
    padding-right: 5px;
  }
  .third-col {
    padding-left: 5px;
    padding-right: 5px;
  }

  .content-type-menu-list{
    .content-type-menu-item{
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      padding: 0.815rem 1.25rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      border-right: none;
      &.selected{
        background-color: #f6f6f6;
        border-right: 1px solid $primary;
      }
      &:not(.last) {
        border-bottom: 1px solid #edf2f9;
      }
      &:hover{
        background-color: #fafafa;
      }
    }
  }
}