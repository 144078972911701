.ant-modal-footer{
  button{
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    line-height: 1;
    height: 2rem;
    border-radius: 0;
  }
}
.ant-modal-wrap.no-content-padding{
  .ant-modal-body{
    padding-left: 0;
    padding-right: 0;
  }
}
.app-wrapper-spinner{
  height: 100vh;
  .ant-spin-spinning{
    z-index: 2000 !important;
    background-color: rgba(0,0,0,0.1);
    max-height: none !important;
  }
}
.visible-scroller{
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(220, 105, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
}
.ant-card {
  &.card-body-reset{
    &.card-body-full{
      .ant-card-body{
        height: 90%;
      }
    }
    &.card-h-padding-0{
      .ant-card-body{
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}
.full-height{
  min-height: 100%;
}
.legislation-draft-mode {
  color: rgba($primary, 0.5) !important;
  font-weight: 400 !important;
}
.ant-checkbox{
  .ant-checkbox-inner{
    border-color: #cccfd4;
  }
}
@import "menu";
@import "table";
@import "header";
@import "users";
@import "content";
@import "categories";