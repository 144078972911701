.content-edit-form{
  .ant-form-item-label {
    overflow: initial;
    white-space: normal;
    label {
      word-wrap: break-word !important;
    }
  }
}
.references-holder{
  margin-top: 30px;
  margin-bottom: 20px;
  hr{
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .no-reference{
    font-size: 80%;
  }
  .add-reference{
    padding: 5px 20px;
    height: auto;
    border-radius: 7px;
  }

  .reference-list{
    margin-bottom: 20px;
    .reference-item{
      display: flex;
      align-items: center;
      padding: 5px 15px;
      margin-bottom: 5px;
      border: 1px solid $border-color;
      border-radius: 5px;
      &:nth-child(odd){
        background-color: rgba($border-color, 0.4);
      }

      .info{
        flex-grow: 2;
        .name{
          font-weight: 600;
        }
        .legislation{
          span{
            font-weight: 600;
            font-size: 80%;
          }
        }
      }
      .actions{
        button{
          padding: 0.2rem 1.0rem;
          line-height: 1;
          height: auto;
        }
      }
    }
  }
}
.tax247-content-wrapper{
  .image-holder{
    margin-top: 20px;
    margin-bottom: 10px;
    .image{
      height: 100px;
      width: 100px;
      margin: 0 auto;
      background-size: cover;
      background-position: center;
    }
  }
  .is-featured{
    font-size: 80%;
    text-align: center;
    color: $primary;
  }
  .synposis{
    border-bottom: 1px solid rgba(0,0,0,0.1);
    font-size: 90%;
    padding-bottom: 10px;margin-bottom: 30px;
  }
  &.legislation{
    .leg-sec-subsec{
      display: inline-block;
      margin-bottom: 5px;
      margin-right: 5px;
      .ant-tag.tagging{
        padding: 0 15px;
        font-size: 110%;
        cursor: pointer;
      }
      .ant-badge-count{
        font-size: 10px;
        padding: 0 2px;
        right: 10px;
      }
      &.is-active{
        .ant-tag{
          color: #ff6b72;
          font-size: 100%;
          background: rgba(255, 107, 114, 0.1);
          border-color: rgba(255, 107, 114, 0.13);
          //font-weight: 500;
        }
      }
      &.is-draft{
        .ant-tag{
          border: 3px dotted rgba($gray-light, 0.3);
        }
      }
      &.is-unpublished {
        .ant-tag{
          text-decoration: line-through;
          color: rgba($gray-light, 0.3);
        }
      }
    }
    .section-wrapper{
      margin-top: 30px;
      .section-wrapper-inner{
        min-height: 100px;
        .section-content{
          padding: 10px 20px;
          .section-content-inner{
            max-height: 200px;
            @extend .visible-scroller;
          }
        }
        .ant-card-actions{
          margin-bottom: 5px;
          margin-left: 5px;
          margin-right: 5px;
          border-bottom-left-radius: 0.625rem;
          border-bottom-right-radius: 0.625rem;

          .ant-switch-handle{
            border-right: none;
          }
        }
      }
    }
    .sub-section-wrapper{
      margin-top: 30px;
      .sub-section-wrapper-inner{
        min-height: 100px;
        .ant-card-actions{
          margin-bottom: 5px;
          margin-left: 5px;
          margin-right: 5px;
          border-bottom-left-radius: 0.625rem;
          border-bottom-right-radius: 0.625rem;
          .ant-switch-handle{
            border-right: none;
          }
        }
        .sub-section-content-inner{
          max-height: 200px;
          @extend .visible-scroller;
        }
      }
    }
    .section-menu{
      button{
        margin-right: 5px;
      }
    }
    .content-title-wrapper{
      text-align: center;
      padding-left: 30px;
      padding-right: 30px;
      hr{
        margin-top: 10px;
        margin-bottom: 10px;
        width: 60%;
      }
    }
    .menu-items{
      margin-top: 30px;
      border-radius: 0;
      .ant-card-body{
        padding-bottom: 5px;
        padding-top: 5px;
      }
      .ant-card-body{
        .ant-menu{
          border-bottom: none;
        }
      }
    }
    .meta{
      margin-top: 30px;
      .firstThird, .secondThird, .lastThird{
        :first-child{
          font-weight: 600;
          font-size: 105%;
        }
      }
      .firstThird{

      }
      .secondThird{
        text-align: center;
      }
      .lastThird{
        text-align: right;
      }
    }
  }
}

.subleg-schedule-modal{
  .ant-modal-content{
    .ant-modal-body{
      padding-left: 0!important;
      padding-top: 0!important;
      padding-bottom: 0!important;
      padding-right: 10px!important;
      .main-content{
        padding-right: 0!important;
      }
      .side-content{
        min-width: 400px;
        max-width: 400px;
      }
      .schedule-menu{
        background-color: #f5f5f5;
      }
      .filter{
        border-top: 1px solid #d9d9d9;
        padding: 10px 15px;
        background-color: #f1f1f1;
        font-weight: 600;
        font-size: 80%;
      }
      .list-wrapper{
        min-height: calc(100vh - 200px - 25px * 2 - 2px);
        max-height: calc(100vh - 200px - 25px * 2 - 2px);
        width: 100%;
        overflow: auto;
        &.loading-state {
          .ant-spin-nested-loading {
            top: 40%;
          }
        }

        .subleg-sch-menu-list{
          .subleg-sch-item{
            transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            padding: 0.815rem 1.25rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            border-right: none;
            font-size: 90%;
            position: relative;
            &.not-published{
              text-decoration: line-through;
              color: rgba($gray-light, 0.3);
            }
            &.is-draft{
              &:before{
                position: absolute;
                //width: 100%;
                right: 0;
                left: 0;
                top: 0;
                bottom: 0;
                content: " ";
                border: 3px dotted rgba($gray-light, 0.3);
              }
              &.selected{
                &:before{
                  border-right: none;//3px solid $primary;
                }
              }
            }
            &.selected{
              background-color: #f6f6f6;
              border-right: 3px solid $primary;
            }
            &:not(.last) {
              border-bottom: 1px solid #edf2f9;
            }
            &:hover{
              background-color: #fafafa;
            }
          }
        }

      }

      .sub-schedule-details{
        .references-holder{
          border: 5px solid $border-color;
          margin-top: 10px;
          padding: 10px 20px;
        }
        .details-content{
          max-height: calc(100vh - 170px - 25px * 2 - 2px);
          overflow: auto;
          //height: 90%;
          //overflow: auto;
        }
      }
      .sub-schedule-editor{
        .edit-content{
          max-height: calc(100vh - 170px - 25px * 2 - 2px);
          overflow: auto;
        }
      }
    }
  }
}

.leg-content-reindex-modal {
  .ant-modal-content {
    .ant-modal-body {
      max-height: calc(100vh - 200px - 25px * 2 - 2px);
      overflow: auto;
    }
  }
}

.image-field-holder {

  display: flex;
  align-items: flex-end;
  .image{
    width: 100px;
    height: 100px;
    background-position: center;
    background-size: cover;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    -webkit-transition: border .24s ease-in-out;
    transition: border .24s ease-in-out;
    margin-right: 10px;
  }
  button{
    height: auto;
  }

}